import {reactFetchJSON, type JSONRequestInit} from '@github-ui/verified-fetch'
import {ResponseError} from './response-error'
import {reportTraceData} from '@github-ui/internal-api-insights'

type QueryFnPartialQueryKey = {
  queryDeps: {
    pathname: string
    searchParams?: ConstructorParameters<typeof URLSearchParams>[0]
    init?: JSONRequestInit
  }
}
/**
 * Intended for use by `queryFn`s that need to make fetch requests.
 * In addition to calling `reactFetchJSON` to add the appropriate react headers to the request,
 * this function will also check the response status and throw a `ResponseError` if necessary,
 * as well as hooking into `reportTraceData`.
 */
export async function queryFnFetch<Response>({queryDeps: {pathname, searchParams, init}}: QueryFnPartialQueryKey) {
  const path = serializePath(pathname, searchParams)

  const response = await reactFetchJSON(path, init)
  if (!response.ok) {
    throw new ResponseError(response.statusText, response)
  }

  const json = (await response.json()) as Response
  reportTraceData(json)
  return json
}

function serializePath(pathname: string, searchParams?: QueryFnPartialQueryKey['queryDeps']['searchParams']) {
  try {
    const path = [pathname]
    const search = new URLSearchParams(searchParams).toString()
    if (search) path.push(search)
    return path.join('?')
  } catch {
    return pathname
  }
}
