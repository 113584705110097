import type {queryOptions, QueryMeta} from '@tanstack/react-query'
import {QueryRouteQueryType, type QueryDepsFn} from './data-router-types'
import {queryFnFetch} from './query-fn-fetch'

// note the `any` for `RoutePath` here means we don't get fully typed `params` object in the queryDeps function
// but that should be fine since that's only internal to reusable query configs.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RelaxedQueryDepsFn<T> = (...args: Parameters<QueryDepsFn<any>>) => T

type QueryOptions<Deps> = Omit<Parameters<typeof queryOptions>[0], 'queryFn' | 'queryKey'> & {
  /**
   * Specialized version of query deps that returns the `string` url path from which to request data
   */
  queryDeps?: RelaxedQueryDepsFn<Deps>
} & {staleTimeForNavigation?: number}

/**
 * A relaxed version of {@link QueryRouteQueryConfig} that allows for a more independent API
 * at the cost of some type safety. Specifically, it relaxes many of the generics from `QueryRouteQueryConfig`
 */
export type RelaxedQueryRouteQueryConfig<Res, Deps, QueryName extends string> = QueryOptions<Deps> & {
  queryName: QueryName
  /**
   * The queryFn to call.
   * This accepts dependencies returned from the queryDeps function if one exists and returns a response to cache.
   */
  queryFn: (
    queryKey: {
      appName: string
      routeId: string
      routePath: string
      queryName: QueryName
      queryDeps: Deps
    },
    opts: {signal: AbortSignal; meta: QueryMeta | undefined},
  ) => Promise<Res> // The argument type of `queryFn` is tied to `queryDeps`
  /**
   * The {@link QueryRouteQueryType} type of query to initiate
   */
  type?: QueryRouteQueryType
}

type QueryFnFetchDeps = Parameters<typeof queryFnFetch>[0]['queryDeps']

export function mainQuery<Res>({...opts}: QueryOptions<QueryFnFetchDeps> = {}): RelaxedQueryRouteQueryConfig<
  Res,
  QueryFnFetchDeps,
  'mainQuery'
> {
  return {
    queryName: 'mainQuery',
    queryDeps: ({pathname, searchParams}) => ({pathname, searchParams}),
    queryFn: async ({routeId, queryDeps}) => {
      const json = await queryFnFetch<{payload: Record<string, Record<string, Res>>}>({queryDeps})
      const payloadForQuery = json.payload?.[routeId]?.['mainQuery']
      if (!payloadForQuery) {
        throw new Error(`Unable to find payload for ${routeId}: mainQuery`)
      }
      return payloadForQuery
    },
    type: QueryRouteQueryType.Blocking,
    ...opts,
  }
}
